@import "mixins"
@font-face
  font-family: "helveticaNeue"
  src: url("/fonts/HelveticaNeueLTStd/HelveticaNeueLTStd-Cn.otf"),url("/fonts/HelveticaNeueLTStd/HelveticaNeueLTStd-Cn.eot"),url("/fonts/HelveticaNeueLTStd/HelveticaNeueLTStd-Cn.svg"),url("/fonts/HelveticaNeueLTStd/HelveticaNeueLTStd-Cn.woff")

@font-face
  font-family: "helveticaNeueBoldCondensed"
  src: url("/fonts/HelveticaNeueBoldCondensed/HelveticaNeueBoldCondensed.otf")

@font-face
  font-family: "currency"
  src: url("/fonts/currency/icomoon.ttf"), url("/fonts/currency/icomoon.eot"), url("/fonts/currency/icomoon.svg"), url("/fonts/currency/icomoon.woff")

html body
  color: rgb(var(--primaryColor))
  min-width: 1000px
  overflow-x: auto
  overflow-y: hidden
  background: var(--body_bg)

  #__next
    height: 100%
    max-width: var(--page_width)
    padding: var(--page_padding)
    margin: 0 auto

  h1
    font-size: 18px
    line-height: 19px

  h2
    font-size: 16px
    line-height: 17px

  h3
    font-size: 14px
    line-height: 15px

  .slick-prev, .slick-next
    z-index: 1
    width: 26px
    height: 26px
    border-radius: 100%
    border: 1px solid rgb(var(--primaryColor))
    background: transparent
    @include flex(true)
    justify-content: center

    &:before
      content: ""
      @include arrowIcon

    &:hover
      border-color: rgb(var(--secondaryColor))

    &:active
      opacity: 0.5 !important

    &.slick-disabled
      opacity: 0.6 !important
      pointer-events: none
      border-color: rgb(var(--base_textColor))

    &.slick-prev
      left: 16px

      &:before
        transform: rotate(90deg)

    &.slick-next
      right: 16px

      &:before
        transform: rotate(-90deg)

  a.activeLink
    cursor: default !important
    pointer-events: none !important

  .svg-sport-icon
    min-width: 16px
    height: 16px

    svg
      path
        fill: var(--sportIcon_fill) !important

  .svg-region-icon
    min-width: 16px
    height: 13px
    margin-right: 8px
    > *
      @include flex

  .iti-flag
    min-width: 20px

  .icon-currency-xrp:before
    content: "\e901"
    font-family: var(--specialFontCurrency)
    margin: 0 1px 0 0
    font-size: 8px

  .icon-currency-trx:before
    content: "\e900"
    font-family: var(--specialFontCurrency)
    margin: 0 1px 0 -1px
    font-size: 11px

  .imageNext
    display: flex

  .main-tabs
    overflow: hidden
    background: var(--base_mainTabBg)
    border-bottom: var(--base_mainTabsBorder)
    @include flex
    z-index: 1
    position: relative

    .main-tabs-tab
      flex: 1 100%
      @include flex
      height: var(--base_mainTabHeight)
      justify-content: center
      text-transform: uppercase
      color: rgba(var(--base_textColor), 0.8)
      @include bold
      position: relative
      font-size: var(--button_fontSize)
      line-height: 13px
      border-radius: var(--base_mainTabRadius)

      &:hover:not(&.active)
        @include pointer
        color: rgba(255, 255, 255, 0.8)

      &:not(.active):not(:first-of-type)
        border-left: 1px solid var(--base_mainTabBorder)

      &.active
        background: var(--base_mainTabActiveBg)
        color: var(--base_mainTabActiveColor)

  .no-game
    background: var(--noGame_bg)
    border: 1px dashed var(--noGame_border)
    border-radius: 3px
    @include flex
    justify-content: center
    height: 32px
    margin: 10px
    padding: 0 10px
    color: rgb(var(--primaryColor))

  .svg-icon > *
    display: flex

  .viewPass
    position: absolute
    right: 2px
    top: 6px
    @include pointer
    opacity: 0.8
    width: 13px
    height: 10px
    background-color: rgb(var(--primaryColor))
    mask-image: var(--icon-password-hide)

    &.showPass
      height: 8px
      margin-top: 1px
      mask-image: var(--icon-password-show)

    &:hover
      opacity: 1

  .ui-switch-container
    @include flex

  .ui-switch
    position: relative
    display: inline-block
    width: 28px
    height: 16px
    margin: 0 6px

    input
      opacity: 0
      width: 0
      height: 0

      &:checked + .ui-switch-slider
        background-color: #2196f3

      &:focus + .ui-switch-slider
        box-shadow: 0 0 1px #2196f3

      &:checked + .ui-switch-slider:before
        transform: translateX(12px)

    .ui-switch-slider
      position: absolute
      cursor: pointer
      top: 0
      left: 0
      right: 0
      bottom: 0
      background-color: #ccc
      transition: 0.4s
      border-radius: 28px

      &:before
        position: absolute
        content: ""
        height: 12px
        width: 12px
        left: 2px
        bottom: 2px
        background-color: white
        transition: 0.4s
        border-radius: 50%

  .ui-checkbox
    input[type="checkbox"]
      position: absolute
      left: -999999px

    > i
      background: var(--checkbox_bg)
      min-width: 12px
      width: 12px
      height: 12px
      vertical-align: top
      @include flex
      justify-content: center

    &.checked input[type="checkbox"] + i:before
      content: ""
      width: 8px
      height: 8px
      background-color: var(--checkbox_checked_fill)
      mask-image: var(--icon-checkbox-check)
      opacity: 0.8

  .no-data
    border-bottom: 0 !important
    cursor: default !important
    background: transparent !important

    > div
      color: rgba(var(--primaryColor), 0.6)
      border-radius: 2px
      @include flex
      justify-content: center
      text-transform: uppercase
      width: 260px
      height: 34px
      padding: 0 10px
      margin: 60px auto 0
      @include bold
      font-size: 11px

.rc-notification
  position: fixed
  z-index: 1000

  &-notice
    border-radius: 3px
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2)
    background: transparent
    display: block
    width: auto
    line-height: 1.5
    position: relative
    margin: 10px 0

  &:has(.notification-modal)
    background: var(--modal_backdropBg)

  .notification-modal
    background: var(--register_bg)
    border-radius: 8px
    width: 360px
    min-height: 200px
    display: flex
    padding: 0 16px 14px
    .rc-notification-notice-content
      display: flex
      flex-direction: column
      flex: 1
      > div
        @include flex
        height: 48px
        border-bottom: var(--login_headerBorder)
        border-bottom-style: dotted
        font-size: 19px
        color: rgb(var(--particularColor2))
        @include bold
      > p
        flex: 1
        @include flex
        color: rgba(var(--secondaryColor))
        @include bold
        line-height: 20px
        font-size: 14px
        padding: 8px 10px
      > button
        width: 100%
        max-width: 100%
        border-radius: var(--base_borderRadius)
        height: var(--field_height)
        font-size: var(--button_fontSize)
        margin-top: auto

    .rc-notification-notice-close
      background: var(--modal_closeBg)
      border-bottom-left-radius: 15px
      border-top-right-radius: 8px
      @include flex
      justify-content: center
      width: 26px
      height: 30px
      position: absolute
      font-size: 0
      top: 0
      right: 0
      z-index: 2

      &:before
        @include closeIcon

      &:hover
        @include pointer

        &:before
          opacity: 1

  .notification-default .rc-notification-notice-close
    width: 8px
    height: 8px
    background-color: rgb(var(--secondaryColor))
    mask-image: var(--icon-button-cancel)
    position: absolute
    right: 10px
    top: 10px

    &:hover
      opacity: 0.8
      @include pointer

  @mixin fade-effect
    animation-duration: 0.3s
    animation-fill-mode: both
    animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2)

  &-fade-appear, &-fade-enter
    opacity: 0
    @include fade-effect
    animation-play-state: paused

  &-fade-leave
    @include fade-effect
    animation-play-state: paused

  &-fade-appear#{&}-fade-appear-active, &-fade-enter#{&}-fade-enter-active
    animation-name: rcNotificationFadeIn
    animation-play-state: running

  &-fade-leave#{&}-fade-leave-active
    animation-name: rcDialogFadeOut
    animation-play-state: running

@keyframes rcNotificationFadeIn
  0%
    opacity: 0
  100%
    opacity: 1

@keyframes rcDialogFadeOut
  0%
    opacity: 1
  100%
    opacity: 0
